import { User } from '../../../interfaces/User'

// State Types
export interface UserState {
  currentUser: User | null
  usersAccounts: Partial<any>[]
}

// Action Types

export const CLEAR_CURRENT_USER = '@user/CLEAR_CURRENT_USER'
export const UPDATE_CURRENT_USER = '@user/UPDATE_CURRENT_USER'
export const REMOVE_SAVED_USER = '@user/REMOVE_SAVED_USER'
export const UPDATE_USER_PREFERENCES = '@user/UPDATE_USER_PREFERENCES'

interface UpdateCurrentUserAction {
  type: typeof UPDATE_CURRENT_USER
  payload: Partial<any>
}

interface ClearCurrentUserAction {
  type: typeof CLEAR_CURRENT_USER
}

interface UserSavedAccountIdentifiers {
  uid: string
}
interface RemoveSavedUserAction {
  type: typeof REMOVE_SAVED_USER
  payload: UserSavedAccountIdentifiers
}

interface UpdateanyAction {
  type: typeof UPDATE_USER_PREFERENCES
  payload: Partial<any>
}

export type UserActionTypes =
  | UpdateCurrentUserAction
  | ClearCurrentUserAction
  | RemoveSavedUserAction
  | UpdateanyAction
